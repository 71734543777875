document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Create Google Maps
  -------------------------------------------------- */
  pgJobsViewCreateGoogleMaps = function(){
    let googleMaps = document.getElementById('pages-jobs-view-map-google_maps');

    if (googleMaps) {
      let firstJobWithLocation = jobsData.find(job => job.location_lat && job.location_lng);
      let map = new google.maps.Map(googleMaps, { zoom: 7, mapTypeControl: false, center: { lat: firstJobWithLocation.location_lat, lng: firstJobWithLocation.location_lng } });
      let mapInfoWindow = new google.maps.InfoWindow;
      let mapMarkers = [];

      for (const [index, job] of jobsData.entries()) {
        if (job.location_lat && job.location_lng) {
          let mapMarker = new google.maps.Marker({ position: new google.maps.LatLng(job.location_lat ,job.location_lng), map: map, title: job.name });
          mapMarkers.push(mapMarker);
          google.maps.event.addListener(mapMarker, 'click', function() {
            mapInfoWindow.setContent('\
              <div class="pg-jobs-view-map-embed-title">' + job.name + '</div> \
              <div class="pg-jobs-view-map-embed-location">' + job.location + '</div> \
              <a href="/pages/jobs/' + job.id + '/view/show" class="pg-jobs-view-map-embed-link">View Project &rarr;</a>\
            ');
            mapInfoWindow.setOptions({ maxWidth: 240 });
            mapInfoWindow.open(map, this);
          });
          document.querySelector('[data-js="job-card"][data-index="' + index + '"]').addEventListener('mouseenter', (e) => {
            mapInfoWindow.close();
            google.maps.event.trigger(mapMarkers[index], 'click');
          });
        }
      }
    }
  }
});

pgJobsViewCreateGoogleMapsWhenPageReady = function(){
  if (typeof pgJobsViewCreateGoogleMaps == 'undefined') {
    document.addEventListener('turbo:load', function(e) {
      pgJobsViewCreateGoogleMaps()
    })
  } else {
    pgJobsViewCreateGoogleMaps()
  }
}
